<template>
  <!-- 申报材料 -->
  <div class="main-contain">
    <template v-if="datalist.length >0">
      <div class="item" v-for="(item, index) in datalist" :key="index" @click="downLoad(item)">
        <el-image class="elimage" :src="icon1" />
        <div class="text">{{ item.name }}</div>
      </div>
    </template>
    <template v-else>
      <div class="is-empty" style="text-align: center;">
        <img :src="no_math">
        <div style="font-size: 24px; font-weight: bold; color: #b5a2a2;margin-bottom: 30px;">
          <span>暂无申报材料</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { getVRAttachments } from "@/api/vr";
export default {
  name: "Courseintroduction",
  components: {},
  data() {
    return {
      datalist: [],
      icon1: require("@/assets/coursedetails/文件类型-标准图-幻灯片.png"),
      icon2: require("@/assets/coursedetails/文件类型-标准图-声音文件.png"),
      icon3: require("@/assets/coursedetails/文件类型-标准图-视频文件.png"),
      no_math: require('@/assets/no_math1.png'),
    };
  },
  methods: {
    initdata(data) {
      this.getVRAttachments({
        vr_id: data.id,
        classify: 2,
        page: 1,
        per_page: 100
      });
    },
    getVRAttachments(params) {
      getVRAttachments(params)
        .then((response) => {
          this.datalist = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    downLoad(item) {
      let link = document.createElement('a')
      link.style.display = 'none'
      link.href = item.url
      link.setAttribute('download', item.name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link);
    }
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  padding: 10px;
  background: #ffffff;

  .item {
    margin-top: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    .elimage {
      margin-right: 10px;
      width: 16px;
      height: 20px;
    }
    .text {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
    }
  }
}
</style>
