<template>
  <!-- 数据分析 -->
  <div class="main-contain">
    <div class="title">实验情况统计</div>
    <div class="chartcontain">
      <div class="contain">
        <chart1 height="100%" width="100%" />
      </div>
    </div>
    <div class="title titlemargintop2">访问时长统计</div>
    <div class="chartcontain">
      <div class="contain">
        <chart2 height="100%" width="100%" />
      </div>
    </div>
  </div>
</template>
<script>
import Chart1 from "@/components/Charts/experimentstatistics";
import Chart2 from "@/components/Charts/durationstatistics";
export default {
  name: "Courseintroduction",
  components: { Chart1, Chart2 },
  data() {
    return {

    };
  },
  methods: {
    initdata(item) {
      console.log(item)
    }
  },
};
</script>
<style lang="scss" scoped>
.main-contain {
  background: #ffffff;
  position: relative;
  padding: 20px;
  margin-bottom: 40px;
  .title {
    font-size: 20px;

    font-weight: bold;
    color: #222222;
  }
  .titlemargintop {
    margin-top: 40px;
  }
  .titlemargintop2 {
    margin-top: 40px;
  }
  .chartcontain {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    height: 400px;

    .contain {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      .y_time {
        position: absolute;
        transform: rotate(-90deg);
        top: 160px;
        left: 0px;
      }
      .y_time2 {
        left: -20px;
      }
      .x_time {
        margin-top: 10px;
        text-align: center;
      }
    }
  }
}
</style>
