<template>
  <div :id="id" :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import { getvrcounttrend } from "@/api/vrdatacount";
import * as echarts from "echarts";
import resize from "./mixins/resize";

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    id: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "200px",
    },
    height: {
      type: String,
      default: "200px",
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    // this.initChart();
    var vr_id = decodeURIComponent(this.$route.query.id);
    this.getvrcounttrend({
      vr_id:vr_id
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    getvrcounttrend(params) {
      getvrcounttrend(params)
        .then((response) => {
          if(response.code==0){
            if(response.data&&response.data.length>0){
              let xdata=[],vr_count=[],vr_yes_count=[],vr_no_count=[];
              response.data.forEach(item => {
                xdata.push(item.date);
                vr_count.push(item.vr_count);
                vr_yes_count.push(item.vr_yes_count);
                vr_no_count.push(item.vr_no_count)
              });
              this.initChart(xdata,vr_count,vr_yes_count,vr_no_count);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initChart(xdata,vr_count,vr_yes_count,vr_no_count) {
      this.chart = echarts.init(document.getElementById(this.id));
      var option;

      option = {
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["总实验量", "已完成", "未完成"],
          left: "10",
          top: "10",
          icon: "rect",
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data:xdata
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "总访问量",
            type: "line",
            stack: "总量",
            data: vr_count,
          },
          {
            name: "已完成",
            type: "line",
            stack: "总量",
            data: vr_yes_count,
          },

          {
            name: "未完成",
            type: "line",
            stack: "总量",
            data:vr_no_count,
          },
        ],
      };

      this.chart.setOption(option);
    },
  },
};
</script>
